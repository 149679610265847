import { useState, useCallback } from 'react';

const usePostIcons = () => {
  const [normalPostIcons, setNormalPostIcons] = useState(['laugh', 'like']);
  const [importantPostIcons, setImportantPostIcons] = useState([
    'laugh',
    'like',
  ]);
  const [eventPostIcons, setEventPostIcons] = useState(['laugh', 'like']);

  const updatePostIcons = useCallback(
    (postId, reaction) => {
      const iconConfig = {
        1: [normalPostIcons, setNormalPostIcons],
        2: [importantPostIcons, setImportantPostIcons],
        3: [eventPostIcons, setEventPostIcons],
      };

      const [icons, setIcons] = iconConfig[postId] || [];

      if (icons) {
        setIcons((prevIcons) => {
          const updatedIcons = [reaction, ...prevIcons].slice(0, 2);
          return updatedIcons;
        });
      }
    },
    [normalPostIcons, importantPostIcons, eventPostIcons],
  );

  return {
    updatePostIcons,
    normalPostIcons,
    importantPostIcons,
    eventPostIcons,
  };
};

export default usePostIcons;
