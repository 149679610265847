import React from 'react';
import ThemeSwitch from './ThemeSwitch';
import Footer from './Footer';

const DecoratedContainer = ({
  children,
  theme,
  toggleTheme,
  showThemeSwitch,
}) => {
  return (
    <div className="App">
      <div className="decorations">
        <div className="ellipse-cyan"></div>
        <div className="ellipse-blue"></div>
        <div className="ellipse-cyan"></div>
        <div className="ellipse-blue"></div>
      </div>
      {showThemeSwitch && (
        <div
          className="section"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ThemeSwitch
            theme={theme}
            toggleTheme={toggleTheme}
            style={{ width: 250, marginLeft: 'auto', marginRight: 'auto' }}
          />
        </div>
      )}
      {children}
      <Footer />
    </div>
  );
};

export default DecoratedContainer;
