import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  typography: {
    fontFamily: '"League Spartan", sans-serif',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#E2EAF0',
      text: {
        primary: '#E2EAF0',
      },
    },
    secondary: {
      main: '#FFF',
    },
    border: '1px solid #121B22',
    iconStyle: {
      color: '#FFF',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        hr: {
          backgroundColor: '#516473',
        },
        body: {
          backgroundColor: '#0a0d11',
          color: '#FFF',
        },
        span: {
          color: '#FFF',
        },
        'span.text': {
          color: '#ACB8C1',
        },
        'span.text-special': {
          color: '#4B75D3',
        },
        'span.text-grey': {
          color: '#ACB8C1',
        },
        b: {
          color: '#FFF',
        },
        '.section-media': {
          background: '#121B22',
        },
        '.section-posts': {
          backgroundColor: '#0E1419',
        },
        '.card': {
          border: '2px solid #121B22',
          background: '#0E1419',
        },
        '.note': {
          border: '2px solid #121B22',
          background: 'linear-gradient(146deg, #0E1419 1.29%, #121B22 96.72%)',
        },
        '.post-important': {
          background: 'linear-gradient(90deg, #412126 0%, #1e171c 100%)',
        },
        '.post-regular': {
          background: 'linear-gradient(90deg, #243643 0%, #121B22 100%)',
        },
        '.post-event': {
          background: 'linear-gradient(90deg, #1E52C8 0%, #0F2862 114.53%)',
        },
        '.add-reaction': {
          backgroundImage: "url('/icons/add-reaction-dark.svg')",
        },
        '.section-terms': {
          backgroundColor: '#0E1419',

          'span.date': {
            borderRadius: 100,
            padding: '10px 16px 8px 16px',
            fontWeight: 500,
            backgroundColor: '#243643',
          },

          th: {
            backgroundColor: '#121B22',
          },
        },
      },
    },
  },
  customComponents: {
    CssTextField: {
      '& label': {
        fontWeight: 600,
        fontFamily: 'League Spartan, sans-serif',
      },
      '& label.Mui-focused': {
        color: '#FFF',
      },
      '& input': {
        fontFamily: 'League Spartan, sans-serif',
        color: '#9AA7B2',
        fontWeight: 400,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#2D4354',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#fff',
      },
    },
    themeSwitch: {
      display: 'flex',
      padding: '16px 24px',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '12px',
      borderRadius: '120px',
      border: '1px solid #121B22',
      background: 'linear-gradient(146deg, #0E1419 1.29%, #121B22 96.72%)',
      '@media (max-width: 700px)': {
        width: '100%',
        padding: '12px 24px',
      },
    },
    customSwitch: {
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        background: 'linear-gradient(146deg, #4B75D3 47.1%, #7897DE 96.72%)',
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    phoneButton: {
      backgroundColor: '#090D11',
      '&:hover': {
        fontWeight: 600,
        backgroundColor: '#090D11',
      },
    },
    actionButton: {
      backgroundColor: '#090d11',
    },
    reactionPopover: {
      background: 'linear-gradient(146deg, #0E1419 1.29%, #121B22 96.72%)',
      borderColor: '#121B22',
    },
    buttonContainer: {
      backgroundColor: '#090D11',
    },
  },
});
