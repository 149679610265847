import React, { useState } from 'react';
import { IconButton, Popover, Box, Tooltip, styled } from '@mui/material';

const Reaction = styled('i')(() => ({
  '&': {
    width: 32,
    height: 32,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
}));

const reactions = [
  {
    icon: (
      <Reaction
        className="reaction"
        style={{
          backgroundImage: `url('/icons/love.svg')`,
        }}
      />
    ),
    name: 'Love',
  },
  {
    icon: (
      <Reaction
        className="reaction"
        style={{
          backgroundImage: `url('/icons/like.svg')`,
        }}
      />
    ),
    name: 'Like',
  },
  {
    icon: (
      <Reaction
        className="reaction"
        style={{
          backgroundImage: `url('/icons/laugh.svg')`,
        }}
      />
    ),
    name: 'Laugh',
  },
  {
    icon: (
      <Reaction
        className="reaction"
        style={{
          backgroundImage: `url('/icons/sad.svg')`,
        }}
      />
    ),
    name: 'Sad',
  },
  {
    icon: (
      <Reaction
        className="reaction"
        style={{
          backgroundImage: `url('/icons/angry.svg')`,
        }}
      />
    ),
    name: 'Angry',
  },
];

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    borderRadius: '100px',
    border: '1px solid',
    padding: 0,
    boxShadow: 'none',
    ...theme.customComponents.reactionPopover,
  },
}));

const AddReaction = ({ onReactionSelect, postId }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReactionSelect = (reaction) => {
    onReactionSelect(postId, reaction.toLowerCase());
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'reaction-popover' : undefined;

  return (
    <>
      <IconButton
        aria-label="add reaction"
        color="secondary"
        onClick={handleClick}
        aria-describedby={id}
      >
        <i className="icon add-reaction" />
      </IconButton>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box sx={{ display: 'flex', padding: '4px 8px', gap: '4px' }}>
          {reactions.map((reaction, index) => (
            <Tooltip key={index} title={reaction.name}>
              <IconButton
                onClick={() => handleReactionSelect(reaction.name)}
                style={{ padding: 4 }}
              >
                {reaction.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      </StyledPopover>
    </>
  );
};

export default AddReaction;
