import React from 'react';
import Switch from '@mui/material/Switch';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { styled } from '@mui/system';

const ThemeSwitch = styled('div')(({ theme }) => ({
  ...theme.customComponents.themeSwitch,
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  ...theme.customComponents.customSwitch,
}));

function ThemeToggle({ theme, toggleTheme }) {
  const logoSrc =
    theme.palette.mode === 'light' ? '/dark/logo.svg' : '/light/logo.svg';
  const iconStyle = theme.palette.iconStyle;
  const borderStyle = theme.palette.border;

  return (
    <ThemeSwitch className="theme-switch">
      <a href="/" style={{ display: 'flex' }}>
        <img src={logoSrc} className="app-logo-small" alt="logo" />
      </a>
      <hr sx={borderStyle} className="vertical" />
      <div className="theme-switch-toggle">
        <LightModeOutlinedIcon sx={iconStyle} />
        <CustomSwitch
          checked={theme.palette.mode === 'dark'}
          onChange={toggleTheme}
          inputProps={{ 'aria-label': 'controlled' }}
          color="secondary"
        />
        <DarkModeOutlinedIcon sx={iconStyle} />
      </div>
    </ThemeSwitch>
  );
}

export default ThemeToggle;
