import React, { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import './App.scss';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { lightTheme } from './themes/light.theme';
import { darkTheme } from './themes/dark.theme';
import Button from '@mui/material/Button';
import AddReaction from './components/AddReaction';
import usePostIcons from './hooks/post-icons';
import DecoratedContainer from './components/DecoratedContainer';
import { isIOS, isAndroid } from 'react-device-detect';

const PhoneEmulator = lazy(() => import('./components/PhoneEmulator'));
const TermsOfUse = lazy(() => import('./components/TermsOfUse'));
const PrivacyGuidelines = lazy(() => import('./components/PrivacyGuidelines'));
const AccountDeletion = lazy(() => import('./components/AccountDeletion'));

function AppContent() {
  const location = useLocation();
  const isDeleteAccountPage = location.pathname.includes('/account_deletion');
  const [theme, setTheme] = useState(() => {
    return isDeleteAccountPage
      ? 'light'
      : localStorage.getItem('theme') || 'light';
  });
  const currentTheme = theme === 'light' ? lightTheme : darkTheme;

  const [normalPostReactions, setNormalPostReactions] = useState(0);
  const [importantPostReactions, setImportantPostReactions] = useState(0);
  const [eventPostReactions, setEventPostReactions] = useState(0);
  const {
    updatePostIcons,
    normalPostIcons,
    importantPostIcons,
    eventPostIcons,
  } = usePostIcons();

  const onReactionSelect = async (postId, reaction) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/public/posts/${postId}/reactions`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ reaction }),
        },
      );
      await updateReactionCount();
      updatePostIcons(postId, reaction);
    } catch (error) {
      console.error('Noe gikk galt. Vær så snill, prøv på nytt');
    }
  };

  const updateReactionCount = useCallback(async () => {
    const PublicPosts = {
      NO_ADS: 1,
      NO_ALGO: 2,
      NO_BOTS: 3,
    };
    const reactionSetters = {
      1: setNormalPostReactions,
      2: setImportantPostReactions,
      3: setEventPostReactions,
    };

    const fetchPromises = Object.values(PublicPosts).map(async (postId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/public/posts/${postId}/reactions/total`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reactionCount = await response.json();

        reactionSetters[postId](reactionCount);
      } catch (error) {
        console.error('Noe gikk galt. Vær så snill, prøv på nytt');
      }
    });

    await Promise.all(fetchPromises);
  }, []);

  useEffect(() => {
    updateReactionCount();
  }, [updateReactionCount]);

  useEffect(() => {
    if (!isDeleteAccountPage) {
      localStorage.setItem('theme', theme);
    }
  }, [theme, isDeleteAccountPage]);

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  }, []);

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />

      <Routes>
        <Route
          path="/"
          element={
            <DecoratedContainer
              theme={currentTheme}
              showThemeSwitch={true}
              toggleTheme={toggleTheme}
            >
              <main className="section section-landing">
                <div className="text-center">
                  <h1>Ekte mennesker. Din hverdag.</h1>
                  <h4>
                    Kun ekte brukere. Newsfeed uten støyete reklame og
                    algoritmestyrt innhold.
                    <br /> Et komplett sosialt medie - slik det burde være.
                  </h4>
                </div>
                <div className="about">
                  <div className="flag">
                    <span className="text">
                      Laget i <b>Norge</b> <i className="icon flag-norway" />
                    </span>
                  </div>
                  <hr className="vertical" />
                  <div className="person">
                    <span className="text">
                      <b>Personvern i fokus</b> bestem selv hva andre kan se
                    </span>
                  </div>
                </div>
                <div className="app-stores">
                  {(isIOS || (!isIOS && !isAndroid)) && (
                    <a href="https://apps.apple.com/us/app/hudd/id6503947586">
                      <img
                        className="app-link"
                        src={`/${theme}/apple.svg`}
                        alt="apple app store"
                        loading="lazy"
                      />
                    </a>
                  )}

                  {/*{(isAndroid || (!isIOS && !isAndroid)) && (*/}
                  {/*  <a href="/">*/}
                  {/*    <img*/}
                  {/*      className="app-link"*/}
                  {/*      src={`/${theme}/android.svg`}*/}
                  {/*      alt="android app store"*/}
                  {/*      loading="lazy"*/}
                  {/*    />*/}
                  {/*  </a>*/}
                  {/*)}*/}
                </div>
              </main>
              <div className="section section-about">
                <div className="calendar">
                  <img
                    className="dotted-arrow-image"
                    src="/arrow-dotted.svg"
                    alt="dotted arrow"
                    loading="lazy"
                    style={{ marginLeft: '35%', marginTop: '10%' }}
                  />
                  <img
                    src={`/${theme}/calendar.svg`}
                    alt="calendar"
                    className="calendar-image"
                    loading="lazy"
                  />
                  <h3>Interaktiv kalender</h3>
                  <span className="text">
                    Hudd har laget en kalender som kan inneholde alle dine
                    avtaler, på ett sted. Kalenderen kan snakke med, og
                    oppdateres automatisk, av både familie, venner,
                    arrangementer og grupper du er med i. Om du ønsker det.
                  </span>
                </div>
                <div className="phone-container">
                  <img src={`/${theme}/try-app.svg`} alt="try the app" />
                  <Suspense fallback={<div>Loading...</div>}>
                    <PhoneEmulator theme={currentTheme}></PhoneEmulator>
                  </Suspense>
                </div>
                <div className="map">
                  <h3>Bli kjent med nabolaget</h3>
                  <span className="text">
                    Hudd sitt unike nabolagskart viser deg hvem som bor i
                    nabolaget ditt, lokale bedrifter, grupper og eventer.
                  </span>
                  <img
                    className="map-image"
                    src={`/${theme}/map.webp`}
                    alt="map"
                    loading="lazy"
                  />
                  <img
                    className="rotate-180 dotted-arrow-image"
                    src="/arrow-dotted.svg"
                    alt="dotted arrow"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="section section-media">
                <h2>Hudd i media</h2>
                <div>
                  <img src="/eigil.webp" alt="Eigil Tarjem" loading="lazy" />
                  <div className="card">
                    <h3>Lager norsk Facebook-utfordrer</h3>
                    <span className="text">
                      Det har stormet rundt Facebook den siste tida.
                      Facebook-eieren Meta måtte snu etter massiv kritikk av at
                      bilder folk har lagt ut, skal bli brukt i trening av KI.
                      Det er foreløpig stanset.
                    </span>
                    <hr className="horizontal" />
                    <div className="bottom-section">
                      <div>
                        <img
                          src="/nyheter-logo.svg"
                          alt="nyheter newspaper logo"
                          loading="lazy"
                        />
                        <span className="text">Nyheter</span>
                      </div>
                      <Button
                        className="button"
                        type="button"
                        href="https://www.tv2.no/nyheter/innenriks/lager-norsk-facebook-utfordrer/16749849/"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          width: '105px',
                          fontWeight: 500,
                          textTransform: 'none',
                          fontFamily: 'League Spartan, sans-serif',
                          fontSize: '16px',
                        }}
                      >
                        Les mer
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section section-posts">
                <div className="section-title">
                  <span className="text-special">Hudd tenker annerledes</span>
                  <h2>Et sosialt medie uten forstyrrelser</h2>
                </div>
                <div className="notes">
                  <div className="note">
                    <div className="top-bar post-important">
                      <h5>Ingen bot'er - kun ekte mennesker</h5>
                      <span className="text-grey">1 min</span>
                    </div>
                    <div className="note-body">
                      <span className="text">
                        For å bruke Hudd må du identifisere deg med Vipps. Slik
                        sikrer vi at det kun er ekte mennesker på Hudd
                      </span>
                      <img
                        className="no-bots-image"
                        src={`/${theme}/no-bots.webp`}
                        alt="no bots"
                        loading="lazy"
                      />

                      <div>
                        <div className="reactions">
                          {normalPostIcons.map((icon, index) => (
                            <img
                              key={index}
                              src={`/icons/${icon}.svg`}
                              alt={`${icon} icon`}
                              className="icon"
                              loading="lazy"
                              style={{
                                zIndex: 2 - index,
                                position: 'relative',
                                left: index === 1 ? '-15px' : '0',
                              }}
                            />
                          ))}
                          <span className="text-grey">
                            {normalPostReactions} stk reagerte
                          </span>
                        </div>
                        <hr className="horizontal" />
                        <AddReaction
                          onReactionSelect={onReactionSelect}
                          postId="1"
                        ></AddReaction>
                      </div>
                    </div>
                  </div>
                  <div className="note">
                    <div className="top-bar post-regular">
                      <h5>Ingen algoritmer</h5>
                      <span className="text-grey">5 timer</span>
                    </div>
                    <div className="note-body">
                      <span className="text">
                        Vi er utrolig lei av masete, irrelevant innhold som
                        lurer oss til å sløse bort hverdagen vår. På Hudd er det
                        ingen algoritmer - alle innlegg vises i kronologisk
                        rekkefølge
                      </span>
                      <img
                        className="cube-image"
                        src={`/${theme}/cube.webp`}
                        alt="cube"
                        loading="lazy"
                      />
                      <div>
                        <div className="reactions">
                          {importantPostIcons.map((icon, index) => (
                            <img
                              key={index}
                              src={`/icons/${icon}.svg`}
                              alt={`${icon} icon`}
                              className="icon"
                              loading="lazy"
                              style={{
                                zIndex: 2 - index,
                                position: 'relative',
                                left: index === 1 ? '-15px' : '0',
                              }}
                            />
                          ))}
                          <span className="text-grey">
                            {importantPostReactions} stk reagerte
                          </span>
                        </div>
                        <hr className="horizontal" />
                        <AddReaction
                          onReactionSelect={onReactionSelect}
                          postId="2"
                        ></AddReaction>
                      </div>
                    </div>
                  </div>
                  <div className="note">
                    <div className="top-bar post-event">
                      <h5>Uten forstyrrende reklame</h5>
                      <span className="text-grey">4 nov</span>
                    </div>
                    <div className="note-body">
                      <span className="text">
                        Hudd satser på en forretningsmodell der brukerne -
                        valgfritt, kan betale for ekstra funksjoner. Det betyr:
                        ingen slitsomme bannerannonser i appen
                      </span>
                      <img
                        className="no-ads-image"
                        src={`/${theme}/no-ads.webp`}
                        alt="no ads"
                        loading="lazy"
                      />
                      <div>
                        <div className="reactions">
                          {eventPostIcons.map((icon, index) => (
                            <img
                              key={index}
                              src={`/icons/${icon}.svg`}
                              alt={`${icon} icon`}
                              className="icon"
                              loading="lazy"
                              style={{
                                zIndex: 2 - index,
                                position: 'relative',
                                left: index === 1 ? '-15px' : '0',
                              }}
                            />
                          ))}
                          <span className="text-grey">
                            {eventPostReactions} stk reagerte
                          </span>
                        </div>
                        <hr className="horizontal" />
                        <AddReaction
                          onReactionSelect={onReactionSelect}
                          postId="3"
                        ></AddReaction>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DecoratedContainer>
          }
        />
        <Route
          path="retningslinjer_for_personvern"
          element={
            <DecoratedContainer
              theme={currentTheme}
              toggleTheme={toggleTheme}
              showThemeSwitch={true}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <PrivacyGuidelines></PrivacyGuidelines>
              </Suspense>
            </DecoratedContainer>
          }
        ></Route>
        <Route
          path="vilkar_for_bruk"
          element={
            <DecoratedContainer
              theme={currentTheme}
              toggleTheme={toggleTheme}
              showThemeSwitch={true}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <TermsOfUse></TermsOfUse>
              </Suspense>
            </DecoratedContainer>
          }
        ></Route>
        <Route
          path="account_deletion"
          element={
            <DecoratedContainer theme={lightTheme} showThemeSwitch={false}>
              <Suspense fallback={<div>Loading...</div>}>
                <AccountDeletion></AccountDeletion>
              </Suspense>
            </DecoratedContainer>
          }
        ></Route>
      </Routes>
    </ThemeProvider>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
